import React from "react"
import { Fade } from "react-awesome-reveal"
import styled from "styled-components"
import cafeImage1 from "../assets/images/02_CAFE_carousel/cafe_1.jpg"
import cafeImage2 from "../assets/images/02_CAFE_carousel/cafe_2.jpg"
import cafeImage3 from "../assets/images/02_CAFE_carousel/cafe_3.jpg"
import cafeImage4 from "../assets/images/02_CAFE_carousel/cafe_4.jpg"
import cafeImage5 from "../assets/images/02_CAFE_carousel/cafe_5.jpg"
import cafeImage6 from "../assets/images/02_CAFE_carousel/cafe_6.jpg"
import cafeImage7 from "../assets/images/02_CAFE_carousel/cafe_7.jpg"
import cafeImage8 from "../assets/images/02_CAFE_carousel/cafe_8.jpg"
import cafeImage9 from "../assets/images/02_CAFE_carousel/cafe_9.jpg"
import cafeImage10 from "../assets/images/02_CAFE_carousel/cafe_10.jpg"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import "../styles/sliderStyler.css"
import SEO from "../components/SEO"

const PageStyles = styled.div`
  /* scroll-snap-type: y mandatory;
  overflow-y: scroll; */
  height: calc(100vh - 150px);
`

const ImageStyles = styled.div`
  /* scroll-snap-align: start; */
  background-size: cover;
  height: calc(100vh - 150px);
`

const BodyStyles = styled.div`
  /* scroll-snap-align: start; */
  display: flex;
  min-height: calc(100vh - 150px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 130px;

  @media (max-width: 900px) {
    padding-left: 90px;
    padding-right: 40px;
    padding-bottom: 120px;
    padding-top: 45px;
    min-height: 0;
  }

  h1 {
    margin-left: -65px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }

  article {
    columns: 2;
    column-width: 330px;
    column-gap: 30px;
  }
`

const CafePage = props => {
  const [pause, setPause] = React.useState(false)
  const timer = React.useRef()
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    duration: 1000,
    dragStart: () => {
      setPause(true)
    },
    dragEnd: () => {
      setPause(false)
    },
  })

  React.useEffect(() => {
    sliderRef.current.addEventListener("mouseover", () => {
      setPause(true)
    })
    sliderRef.current.addEventListener("mouseout", () => {
      setPause(false)
    })
  }, [sliderRef])

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, 2000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])

  return (
    <>
      <SEO title="Cafe" image={cafeImage1} />
      <Fade triggerOnce>
        <PageStyles>
          <div ref={sliderRef} className="keen-slider">
            <div
              className="keen-slider__slide number-slide1"
              style={{
                backgroundImage: `url(${cafeImage1})`,
              }}
            ></div>
            <div
              className="keen-slider__slide number-slide2"
              style={{ backgroundImage: `url(${cafeImage2})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide3"
              style={{ backgroundImage: `url(${cafeImage3})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide4"
              style={{ backgroundImage: `url(${cafeImage4})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide5"
              style={{ backgroundImage: `url(${cafeImage5})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide6"
              style={{ backgroundImage: `url(${cafeImage6})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide6"
              style={{ backgroundImage: `url(${cafeImage7})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide6"
              style={{ backgroundImage: `url(${cafeImage8})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide6"
              style={{ backgroundImage: `url(${cafeImage9})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide6"
              style={{ backgroundImage: `url(${cafeImage10})` }}
            ></div>
          </div>
          <BodyStyles>
            <div>
              <h1>CAFE</h1>

              <article>
                <p>
                  Open 7:30am to 10pm everyday for breakfast, lunch, dinner and
                  cocktails.
                </p>
                <p>
                  WE LOVE COFFEE, and if you’re anything like us then it’s
                  probably the first thing you think about when your eyes open
                  in the morning. There is nothing quite like a delicious
                  caffienne kick to the face to get your day running in the
                  right direction.
                </p>
                <p>
                  We source the best arabica coffee beans, locally and abroad,
                  giving you premium espresso, milk coffee or cold brew. If you
                  prefer dairy-free, we’ve got your back with plant-based milk
                  alternatives and our expert baristas will make sure you get
                  your coffee exactly how you like it.
                </p>
                <p>
                  Our cafe stares straight onto the breaking waves of beautiful
                  Pehebiya beach in Dickwella. We have indoor and outdoor
                  seating and we are open for breakfast, lunch, sundowner
                  cocktails and dinner everyday!
                </p>
                CLICK TO SEE THE MENU
              </article>
            </div>
          </BodyStyles>
        </PageStyles>
      </Fade>
    </>
  )
}

export default CafePage
